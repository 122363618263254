// Colors
$color_buttons: #1d8348;
$color_cancel: #943126;
$color_texts: #fdfefe;

.container_datePicker_macrometer {
  display: grid;
  width: 100%;
  height: 100%;
  margin-top: -50px;
}

.DatePickerContainer {
  display: grid;
  margin-top: 50px;
  margin-bottom: 50px;

  .DatePickerContainerButtonsDropdown {
    display: grid;
    justify-self: center;
    align-self: center;
    grid-template-columns: 100%;

    .DatePickerButtons {
      display: flex;
      column-gap: 15px;

      .buttons_and_select_date_container {
        display: flex;
        flex-direction: row;
        gap: 2%;
        width: auto;

        // Buttons Day
        .DatePickerButtonBackNext {
          border-radius: 5px;

          .DatePickerButtonBackNextContent {
            display: flex;
            color: $color_texts;
            column-gap: 10px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            background-color: $color_buttons;
            border-radius: 5px;
            font-size: 18px;
            padding: 10px 15px;
            cursor: pointer;
            width: max-content;

            @media (max-width: 768px) {
              font-size: 12px;
            }

            &:hover {
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            }
          }
        }

        // Input
        .DatePickerInput {
          border: none;
          border-radius: 5px;
          cursor: pointer;

          .DatePickerInputContent {
            display: flex;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            border-radius: 5px;
            font-size: 18px;

            @media (max-width: 768px) {
              font-size: 12px;
            }

            &-Icon {
              padding: 10px 5px 8px 10px;
            }

            &-Text {
              width: 330px;
              padding: 10px 5px;

              @media (max-width: 768px) {
                width: max-content;
              }

              @media (max-width: 480px) {
                width: fit-content;
              }
            }
          }
        }
      }

      // Areas
      .DatePickerChangeInside {
        display: grid;
        margin-left: 30px;
        align-self: center;

        &-Select {
          font-size: 18px;
          padding: 10px;
          border: none;
          border-bottom: 1px solid gray;

          &-Option {
            &-Status {
              background-color: red;
              border-radius: 45px;
              color: #fdfefe;
            }
          }
        }
      }
    }
  }

  .DatePickerDropDown {
    position: fixed;
    z-index: 10;
    justify-self: center;
    align-self: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    .DatePickerDropDownContent {
      padding: 5px;
      background-color: $color_texts;

      .DatePickerDropDownButtons {
        display: grid;
        grid-template-columns: 100%;

        &-Buttons {
          padding: 15px 10px;
          column-gap: 10px;
          justify-self: flex-end;
          align-self: center;

          &-Cancel {
            color: $color_texts;
            padding: 5px 15px;
            background-color: $color_cancel;
            border-radius: 5px;
            margin-right: 10px;
            cursor: pointer;
          }

          &-Success {
            background-color: $color_buttons;
            color: $color_texts;
            border-radius: 5px;
            padding: 5px 15px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .DatePickerDropDownOcupation {
    margin-top: 300px;
  }

  @media (max-width: 850px) {
    .DatePickerDropDown {
      margin-top: 400px;
    }
  }
}
