._container_graphic {
  margin: 30px;
  padding: 15px 25px 15px 25px;

  .__data_empty__ {
    display: grid;
    grid-template-columns: 100%;

    .__text_data_empty__ {
      padding-left: 150px;
      justify-self: center;
      align-self: center;
    }
  }

  @media (max-width: $screen_xs) {
    .date_picker_and_graph_container {
      overflow: auto;
      .occupation_report_graph_container {
        display: flex;
        width: max-content;
      }
    }
  }
}

._report_energy {
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 50px;

  ._container_energy {
    padding-top: 50px;

    .occupancy_percentage {
      margin-left: 36px;
      font-size: 15px;
      background-color: rgb(27, 114, 220);
      color: white;
      font-weight: 500;
      padding: 4px 10px;
      border-radius: 6px;
    }

    @media (max-width: $screen_xs) {
      .__title_energy {
        font-size: 15px;
        font-weight: 500;
        padding-bottom: 10px;

        .__icon_report {
          font-size: 16px;
          margin-right: 10px;
          color: $color_green_three;
        }
      }
    }

    @media (max-width: $screen_md) {
      overflow: auto;
    }

    ._container_table {
      margin-top: 25px;
    }

    .__color_title_reportOcupation__ {
      background-color: rgba($color_green_principal, 0.2);
      font-weight: bold;
    }
  }
}

.__container_graphic_report_general_ocupation_title__ {
  padding-left: 55px;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 15px;

  .__icon_report {
    font-size: 20px;
    margin-right: 10px;
    color: $color_green_three;
  }

  @media (max-width: $screen_md) {
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 15px;

    .__icon_report {
      font-size: 16px;
      margin-right: 10px;
      color: $color_green_three;
    }
  }
}

.__container_msg_report_empty__ {
  display: grid;
  grid-template-columns: 100%;
  height: 150px;

  span {
    justify-self: center;
    align-self: center;
    font-size: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgba($color_grey_four, 1);
    padding: 50px 200px;

    i {
      padding-right: 10px;
    }
  }
}

.__title_energy {
  gap: 12px;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 15px;

  .__icon_report {
    font-size: 20px;
    margin-right: 10px;
    color: $color_green_three;
  }
}

.__graphic_report_ocupation_general_container__ {
  display: grid;
  grid-template-columns: 70% 30%;

  @media (max-width: $screen_md) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.date_vacio_report_measurer {
  text-align: center;
  margin: 150px 0 150px 0;

  span {
    font-size: 35px;
    padding: 15px 35px;
    border-radius: 7px;
    background-color: rgba($color_grey_terceary, 0.1);
  }
}

.__table_container_report_general_total__ {
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;

  @media (max-width: $screen_md) {
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    overflow: auto;

    .__title_energy {
      font-size: 15px;
      font-weight: 500;
      padding-bottom: 15px;

      .__icon_report {
        font-size: 16px;
        margin-right: 10px;
        color: $color_green_three;
      }
    }
  }

  @media (max-width: $screen_xmd) {
    margin-top: 50px;
    margin-left: 25px;
    margin-right: 25px;
    overflow: auto;
  }
}

.__areas_report_ocupaction_general__ {
  display: grid;
  grid-template-columns: 45% 10% 45%;

  hr {
    border: 0;
    border-top: 2px solid $color_green_principal;
    width: 70%;
  }

  font-size: 25px;
  margin-top: 50px;
  text-align: center;
  margin-bottom: -40px;

  @media (max-width: $screen_xs) {
    font-size: 18px;
    margin-top: 50px;
    text-align: center;
    margin-bottom: -40px;
  }
}

.occupation_report_container {
  .Datepicker_AreaSelector_Container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  @media (max-width: $screen_xlg) {
    .DatePickerContainer {
      .DatePickerContainerButtonsDropdown {
        .DatePickerButtons {
          display: flex;
          flex-direction: column;
          .buttons_and_select_date_container {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
          }
          .DatePickerChangeInside {
            display: flex;
            justify-content: center;
            margin-left: 0%;
          }
        }
      }
    }
  }

  @media (max-width: $screen_xmd) {
    .Datepicker_AreaSelector_Container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-gap: 10px;
      gap: 0px;
      padding: 10px 10px 20px 10px;
    }
  }
}
